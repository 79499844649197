.video {
    margin-top: 80px;
    background: yellow;
    text-align: center;
    /* line-height: 100px; */
    padding: 4px 4px;
    padding-bottom: 0px;

    background: linear-gradient(to right, white 50%, transparent 0%) top repeat-x,
        linear-gradient(white 50%, transparent 0%) right repeat-y,
        linear-gradient(to right, white 50%, transparent 0%) bottom repeat-x,
        linear-gradient(white 50%, transparent 0%) left repeat-y;
    background-size: 10px 1px, 1px 10px;
}

.video .decorative-squares div {
    position: absolute;
    width: 10px;
    height: 10px;
    background-color: white;
}

.video .decorative-squares div:nth-child(1) {
    top: -5px;
    left: -5px;
}

.video .decorative-squares div:nth-child(2) {
    top: -5px;
    right: -5px;
}

.video .decorative-squares div:nth-child(3) {
    bottom: -5px;
    left: -5px;
}

.video .decorative-squares div:nth-child(4) {
    bottom: -5px;
    right: -5px;
}