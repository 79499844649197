@import "../../assets//styles/variables";

nav {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .logo-small {
        display: none;
    }

    @media(max-width: $tablet) {
        .logo {
            display: none;
        }

        .logo-small {
            display: block;
        }
    }

    @media (max-width: 765px) {
        width: 100%;
        justify-content: space-between;

    }

    .price {
        margin-right: 32px;

        @media (max-width: 765px) {
            display: none;

        }
    }

    .nav-panel {
        display: flex;
        align-items: center;
    }

    a.price-small {
        margin-right: 8px;
        display: flex;
        align-items: center;

        svg {
            width: 32px;
            height: 32px;
            color: $color-text;
        }

        @media (min-width: 765px) {
            display: none;

        }
    }
}