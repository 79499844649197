@import '../../assets/styles/variables';

.spinner {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
}

.spinner div {
    width: 12px;
    height: 12px;
    background-color: #ffffff;
    border-radius: 50%;
    animation: spinner-animation 1.2s linear infinite;
}

.spinner div:nth-child(1) {
    animation-delay: 0s;
}

.spinner div:nth-child(2) {
    animation-delay: 0.3s;
}

.spinner div:nth-child(3) {
    animation-delay: 0.6s;
}

.spinner div:nth-child(4) {
    animation-delay: 0.9s;
}

@keyframes spinner-animation {
    0% {
        transform: scale(0);
        opacity: 0;
    }

    50% {
        transform: scale(1);
        opacity: 1;
    }

    100% {
        transform: scale(0);
        opacity: 0;
    }
}

button.button {
    padding: 14px 34px;
    border: none;
    border-radius: 40px;
    cursor: pointer;
    transition: all 0.3s ease;
    font-weight: 600;
    font-size: 18px;

    &.outlined {
        &.text {
            background-color: transparent;
            color: $color-text;
            border: 2px solid $color-text;

            &:hover {
                background-color: $color-text;
                color: $color-background;
            }

            &.active {
                background-color: $color-text;
                color: $color-background;
            }
        }
    }

    &.filled {
        &.text {
            background-color: $color-text;
            color: $color-background;

            &:hover {
                background-color: darken($color-text, 10%);
            }

            &.active {}
        }

        &.primary {
            background-color: $color-primary;
            color: $color-background;

            &:hover {
                background-color: darken($color-primary, 10%);
            }

            &.active {}
        }
    }

    &.link {
        background-color: transparent;
        color: $color-text;
        border: none;
        padding: 0;

        &:hover {
            text-decoration: underline;
        }
    }

    @media (max-width: 765px) {
        border-radius: 20px;
        padding: 12px 24px;
    }

    &.disabled {
        cursor: not-allowed;
    }
}