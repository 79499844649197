/* autoprefixer grid: on */
@import './assets/styles/variables';

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'DM Sans', sans-serif;

}

body {
  background-color: $color-background;
  color: $color-text;
}

.primary {
  color: $color-primary;
  border-color: $color-primary;
  box-shadow-color: $color-primary;
}

.secondary {
  color: $color-secondary;
  border-color: $color-secondary;
}

.tertiary {
  color: $color-tertiary;
  border-color: $color-tertiary;
}

.quaternary {
  color: $color-quaternary;
  border-color: $color-quaternary;
}

.text {
  color: $color-text;
  border-color: $color-text;
}

.align-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.bold {
  font-weight: 700;
}

.medium {
  font-weight: 600;
}

.body3 {
  font-size: 46px;
  line-height: 130%;

}

.body1 {
  font-size: 82px;
}

.body5 {
  font-size: 18px;
}

.body4 {
  font-size: 26px;
}

.body5 {
  font-size: 18px;
  line-height: 150%;
}

h1 {
  font-size: 72px;
}

h2 {
  font-size: 26px;
  margin-top: 40px;
}

h3 {
  font-size: 26px;
  margin-top: 40px;
}

@media (max-width: $desktop) {
  .body1 {
    font-size: 48px;
  }

  .body5 {
    font-size: 16px;
  }

  .body3 {
    font-size: 44px;
  }

  .body4 {
    font-size: 24px;
  }

  h1 {
    font-size: 56px;
  }

  h3 {
    font-size: 20px;
  }


}

@media (max-width: $tablet) {
  .body1 {
    font-size: 32px;
  }

  .body5 {
    font-size: 20px;
  }

  .body3 {
    font-size: 36px;
  }

  .body4 {
    font-size: 26px;
  }

  h1 {
    font-size: 46px;
  }

  h3 {
    font-size: 18px;
  }

}

@media (max-width: $mobile) {
  .body1 {
    font-size: 34px;
  }

  .body5 {
    font-size: 18px;
  }

  .body3 {
    font-size: 28px;
    text-align: center;
    font-weight: 400;
  }

  .body4 {
    font-size: 24px;
  }

  h1 {
    font-size: 36px;
    line-height: 110%;
  }

  h3 {
    font-size: 24px;
  }

  h2 {
    font-size: 22px;
  }

}