@import '../../assets/styles/variables';

#price {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    h1 {
        font-size: 64px;
        color: $color-secondary;

        @media (max-width: 765px) {
            font-size: 36px;
            margin-top: 32px;

        }
    }

    .box {
        width: 100%;
        max-width: 672px;

        .row {
            display: flex;
            justify-content: space-between;
            border-bottom: 1.5px solid $color-quaternary;
            padding: 32px 0;



            &:nth-child(2n) {
                border-bottom-color: $color-primary;
            }

            &:nth-child(3n) {
                border-bottom-color: $color-primary;
            }

            &:nth-child(4n) {
                border-bottom-color: $color-secondary;
            }

            &:nth-child(5n) {
                border-bottom-color: $color-secondary;
            }
        }

        p {
            font-size: 26px;
            line-height: 130%;
            color: $color-text;

            @media (max-width: 765px) {
                font-size: 18px;
            }

            &.description {
                max-width: 70%;
            }

            &.price {
                text-align: right;
            }
        }
    }
}