@import '../assets/styles/variables';

.root {
    display: flex;
    justify-content: center;
    height: 100vh;

    main {
        flex: 1;

    }

    .main {
        display: flex;
        flex-direction: column;
        max-width: 1150px;
        width: 100%;
        padding: 15px;

        @media(max-width: $mobile) {
            max-width: 320px;
        }
    }

    footer {
        margin-top: 40px;
        border-top: 1px solid $color-border;
        padding-top: 80px;

        a {
            text-decoration: none;
            color: $color-text;
            font-size: 18px;
        }

        .first-level {
            display: flex;
            align-items: flex-start;
            justify-content: space-between;
            flex-wrap: wrap;

            .links {
                display: flex;
                flex-direction: column;
                flex-wrap: wrap;
                max-height: 120px;

                a {
                    margin: 20px 40px;
                    margin-top: 0;
                }

                @media(max-width: $tablet) {
                    display: none;
                }
            }

            .social {
                display: flex;
                align-items: center;
                justify-content: center;

                a {
                    margin: 0 4px;
                }
            }
        }

        .second-level {
            display: flex;
            justify-content: space-between;
            opacity: 0.3;
            margin-top: 80px;

            a {

                font-size: 12px;
            }
        }
    }
}