$font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;

$color-text: #efefef;
$color-background: #151515;
$color-primary: #EF3F9C;
$color-secondary: #84D11C;
$color-tertiary: #EB580A;
$color-quaternary: #837DFF;

$color-border: #2d2d2d;

$desktop: 1150px;
$tablet: 768px;
$mobile: 480px;