// Import any necessary dependencies or variables here

// Define styles for the thank you page
.thankyou-root {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;

    h1 {
        margin-top: 72px;
    }

    p {
        margin-top: 36px;
        font-size: 1.5rem;
        max-width: 736px;
        text-align: center;
    }

    button {
        margin-top: 74px;
    }

    @media (max-width: 765px) {
        p {
            font-size: 1.2rem;
        }
    }
}