@import '../../assets/styles/variables';

#intro {
    text-align: center;
    margin-top: 60px;


    button {
        margin-top: 60px;

        &.secondary-action {
            margin-left: 16px;
            border-color: $color-primary;
            color: $color-primary;

            &:hover {
                background-color: $color-primary;
                color: white;
            }
        }
    }


    @media (max-width: $mobile) {
        .align-center {
            display: flex;
            flex-direction: column;
        }

        button {
            width: 100%;

            &.secondary-action {
                margin-left: 0;
                margin-top: 16px;
            }


        }
    }
}


#numbers {
    width: 100%;

    .content {
        display: flex;
        justify-content: space-between;
        margin-top: 100px;
    }

    @media(max-width: $desktop) {
        display: flex;
        flex-direction: column;
        align-items: center;

        .content {
            // max-width: 900px;
            width: 90%;
            flex-direction: column;
            align-items: flex-start;
        }

        .points {
            margin-top: 40px;
        }
    }

    @media(max-width: $tablet) {
        .content {
            .point {
                margin-top: 16px;
            }
        }
    }

    @media (max-width: $mobile) {

        .content {
            margin-top: 72px;
        }
    }

}

.reveal-on-scroll {
    opacity: 0;
    transform: translateY(20px);

    &.reveal {
        animation: reveal 1s ease forwards;
    }
}

@keyframes reveal {
    0% {
        opacity: 0;
        transform: translateY(20px);
    }

    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

.notification-bar {
    position: fixed;
    display: flex;
    justify-content: center;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: $color-background;
    border-top: 2px solid white;
    color: white;
    padding: 16px;
    text-align: center;
    z-index: 1000;
    box-shadow: 0px -2px 4px rgba(0, 0, 0, 0.1);

    .notification-message {
        display: flex;
        justify-content: space-between;
        align-items: center;
        max-width: 90%;

        p {
            max-width: 70%;
            text-align: left;
            margin-left: 16px;
        }
    }

    .notification-wrapper {
        max-width: 1150px;
        // position: relative;
    }

    .notification-content {
        display: flex;

        @media (max-width: $mobile) {}

    }

    button {
        white-space: nowrap;
    }

    .close-icon {
        position: absolute;
        right: 16px;
        top: 32px;
        transform: translateY(-50%);
        cursor: pointer;
        background: none;
        border: none;

        svg {
            color: white;
        }


    }

    @media (max-width: $mobile) {
        .notification-message {
            flex-direction: column;
            max-width: 100%;

            p {
                max-width: 100%;
                margin: 16px;
            }
        }

        .notification-content {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;
        }

        img {
            display: none;
        }

    }
}

#qualities {
    text-align: center;
    margin-top: 160px;

    @media (max-width: $mobile) {
        margin-top: 80px;
    }
}


#steps {
    .text {
        text-align: center;
        margin-top: 160px;
    }

    .timeline {
        height: 550px;
        position: relative;

        img.line {
            position: absolute;
            top: 0;
            left: -150px;
        }
    }

    .step {
        img {
            transition: transform 0.3s ease-in-out;
            cursor: pointer;

            &:hover {
                transform: scale(1.3);
            }
        }

        &.step-1 {
            top: 307px;
            left: 0;
        }

        &.step-2 {
            top: 159px;
            left: 300px;
        }

        &.step-3 {
            top: 161px;
            left: 700px
        }

        &.step-4 {
            top: -13px;
            left: 1000px;
        }

        position: absolute;

        width: 264px;

        .body4 {
            margin-top: 40px;
        }

        .body5 {
            margin-top: 16px;
        }
    }

    img.line-mobile {
        display: none;
    }

    @media(max-width: $desktop) {

        img.line {
            display: none;
        }

        img.line-mobile {
            display: block;
            position: absolute;
            top: 36px;
            left: 20%;
            height: 82%;
            opacity: 0.5;
        }

        .timeline {
            height: auto;
            position: relative;
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            flex-direction: column;
            align-items: center;
            margin-top: 80px;

            .step {
                margin-top: 32px;
                margin-bottom: 16px;

                padding: 16px;

                &.step-1,
                &.step-2,
                &.step-3,
                &.step-4 {
                    position: relative;
                    top: 0;
                    left: 0;
                    height: 180px;
                    width: 320px;
                    padding-top: 48px;

                    .body4 {
                        margin-top: 8px;
                    }

                    img {
                        position: absolute;
                        top: 0;


                    }
                }

                &.step-1,
                &.step-3 {
                    img {
                        right: 0;

                    }
                }

                &.step-2,
                &.step-4 {
                    img {
                        left: 0;
                    }
                }
            }
        }

    }

    @media(max-width: $tablet) {
        img.line-mobile {
            left: 0;
            width: 100%;
        }

        .step {
            width: 100%;
        }
    }

    @media (max-width: $mobile) {
        .text {
            margin-top: 80px;
        }

        .timeline {
            margin-top: 80px;

            .step {
                margin-top: 48px;
            }
        }



    }
}

#you-get {
    margin-top: 160px;

    @media(max-width: $tablet) {
        margin-top: 80px;

        p.body3 {

            text-align: center;
        }
    }

    .cards {
        margin-top: 80px;
        display: flex;
        justify-content: space-between;

        .card {
            width: 360px;
            padding: 40px;
            border: 1px solid;
            border-radius: 40px;
            transition: box-shadow 0.3s ease-in-out;

            &:hover {
                box-shadow: 0px 0px 40px 0px;
            }

            .body4 {
                color: $color-text;
                margin-top: 40px;
            }

            .body5 {
                color: $color-text;
                margin-top: 20px;
            }
        }

        @media(max-width: $desktop) {
            flex-direction: column;
            align-items: center;

            .card {
                margin-top: 40px;
            }
        }

        @media(max-width: $mobile) {
            .card {
                width: 320px;
                padding: 16px;
                border-radius: 20px;

                p.body4 {
                    margin-top: 20px;

                }
            }


        }
    }

    .button {
        width: 100%;
        display: flex;
        justify-content: center;

        button {
            margin-top: 60px;
            max-width: 456px;
        }
    }
}

#our-services {
    margin-top: 160px;
    display: flex;
    justify-content: space-between;

    @media(max-width: $tablet) {
        margin-top: 80px;
    }

    .list {
        max-width: 456px;
        margin-top: 12px;

        .item {
            margin-bottom: 60px;

            .tags {
                margin-top: 22px;
                display: flex;
                flex-wrap: wrap;

                &.tags-primary {
                    .tag {
                        border-color: $color-primary;
                    }
                }

                &.tags-secondary {
                    .tag {
                        border-color: $color-secondary;
                    }
                }

                &.tags-tertiary {
                    .tag {
                        border-color: $color-tertiary;
                    }
                }

                .tag {
                    margin: 4px;
                    padding: 15px 25px;
                    border: 1px solid;
                    border-radius: 40px;
                }
            }
        }
    }

    @media(max-width: $desktop) {
        flex-direction: column;
        align-items: center;

        .list {
            margin-top: 40px;
        }
    }
}

#portfolio {
    .portfolio-item {
        display: flex;
        justify-content: space-between;
        margin-top: 85px;
        flex-wrap: wrap;

        .link {
            display: flex;
            flex-direction: column;
            align-items: center;

            .circle {
                width: 20px;
                height: 20px;
                border-radius: 100%;
                transition: all 0.3s ease-in-out;

                &.primary {
                    background-color: $color-primary;
                }

                &.secondary {
                    background-color: $color-secondary;
                }

                &.tertiary {
                    background-color: $color-tertiary;
                }

                &.quaternary {
                    background-color: $color-quaternary;
                }


            }

            button {
                background-color: transparent;
                border: none;
                cursor: pointer;
                color: $color-text;
                text-decoration: underline;
                font-size: 26px;
                font-weight: 300;
            }
        }

        img {
            max-width: 325px;
            height: auto;
        }

        .portfolio-item__content {
            max-width: 455px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            p.body5 {
                margin-top: 24px;

            }

            .tags {
                display: flex;
                flex-wrap: wrap;
                gap: 8px;

                .tag {
                    display: flex;
                    align-items: center;
                    padding: 10px 20px;
                    border: 1px solid;
                    border-radius: 40px;

                    span {
                        white-space: nowrap;
                    }

                    &.tag-primary {
                        border-color: $color-primary;
                    }

                    &.tag-secondary {
                        border-color: $color-secondary;
                    }

                    &.tag-tertiary {
                        border-color: $color-tertiary;
                    }

                    &.tag-quaternary {
                        border-color: $color-quaternary;
                    }
                }


            }
        }
    }

    @media (max-width: $desktop) {
        .portfolio-item {
            flex-direction: column-reverse;
            margin-top: 40px;
            align-items: center;

            .tags {
                margin-top: 16px;
            }

            .portfolio-item__img {

                margin-top: 16px;

                img {
                    max-width: 455px;
                    width: 455px;
                }
            }

            .link {
                margin-top: 16px;
                width: 100%;
                max-width: 455px;
                align-items: flex-end;

                .circle {
                    display: none;
                }
            }
        }
    }

    @media (max-width: $mobile) {
        p.body3 {
            text-align: left;
        }

        .portfolio-item {
            .portfolio-item__img {
                img {
                    width: 290px;
                }
            }
        }

    }
}

#reviews {
    margin-top: 100px;

    @media(max-width: $tablet) {
        margin-top: 80px;

        p.body3 {
            text-align: center;
        }
    }

    .list {
        display: flex;
        margin-top: 80px;

        .item {
            margin-left: 32px;
            color: $color-text;
            border: 1px solid $color-text;
            border-radius: 40px;
            padding: 40px;
            display: flex;
            align-items: flex-start;
            transition: transform 0.3s ease-in-out;

            &:hover {
                transform: matrix3d(1.05, 0, 0, 0, 0, 1.05, 0, 0, 0, 0, 1.05, 0, 10, 0, 0, 1) rotateZ(1deg);
            }

            &.primary {
                border-color: $color-primary;
            }

            &.secondary {
                border-color: $color-secondary;
            }

            &.tertiary {
                border-color: $color-tertiary;
            }

            &.quaternary {
                border-color: $color-quaternary;
            }

            svg {
                width: 40px;
                height: 40px;
            }

            .review {
                margin-left: 80px;
                max-width: 300px;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                height: 266px;

                .avatar {
                    display: flex;

                    img {
                        width: 40px;
                        height: 40px;
                        border-radius: 50%;
                    }

                    .title {
                        margin-left: 20px;
                    }
                }
            }
        }

        @media(max-width: $desktop) {
            flex-direction: column;
            align-items: center;

            .item {
                margin-left: 0;
                margin-top: 40px;

                .review {
                    height: auto;

                    .avatar {
                        margin-top: 36px;
                    }
                }
            }
        }

        @media(max-width: $tablet) {
            .item {
                padding: 24px;

                svg {
                    width: 20px;
                    height: 20px;
                }

                .review {
                    margin-left: 16px;

                    .avatar {
                        img {
                            width: 26px;
                            height: 26px;
                        }
                    }
                }

            }
        }
    }

    @media (max-width: $mobile) {
        margin-top: 72px;

        .list {
            margin-top: 40px;
        }
    }
}

#faq {
    margin-top: 160px;

    @media(max-width: $tablet) {
        margin-top: 80px;
    }

    .faqs {
        .faq-item {
            margin-bottom: 32px;
            padding: 18px;
            padding-top: 34px;
            border: 1px solid;
            border-radius: 40px;
            color: $color-text;
            cursor: pointer;
            transition: height 0.3s ease-in-out;
            display: flex;
            flex-direction: column;


            svg {
                transition: transform 0.3s ease-in-out;
                transform: rotate(90deg);
            }

            svg.open {
                transform: rotate(0deg);
            }

            &.primary {
                border-color: $color-primary;

                svg {
                    color: $color-primary;
                }
            }

            &.secondary {
                border-color: $color-secondary;

                svg {
                    color: $color-secondary;
                }
            }

            &.tertiary {
                border-color: $color-tertiary;

                svg {
                    color: $color-tertiary;
                }
            }

            &.quaternary {
                border-color: $color-quaternary;

                svg {
                    color: $color-quaternary;
                }
            }

            .question {
                display: flex;
                align-items: center;
                justify-content: space-between;
            }

            .answer {
                margin-top: 20px;
                overflow: hidden;
                opacity: 0;
                transition: all 0.3s ease-in-out, max-height 0.3s ease-in-out;
                /* Add max-height transition */
                max-height: 0;
                /* Initially hide the answer content */

                &.expanded {
                    opacity: 1;
                    max-height: 1000px;
                    /* Set to a high value to allow for dynamic content */
                }
            }

            &:first-child {
                margin-top: 80px;
            }

            @media (max-width: $mobile) {
                padding: 16px;
                padding-top: 24px;
                border-radius: 20px;

            }
        }
    }

    p.body3 {
        width: 100%;
        text-align: center;
    }
}

#cat {
    margin-top: 80px;
    margin-bottom: 160px;
    display: flex;
    justify-content: center;
    flex-direction: column;

    @media(max-width: $tablet) {
        margin-bottom: 20px;
    }

    .notification {
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #76BA1B;
        color: white;
        padding: 16px;
        border-radius: 20px;
        z-index: 1000;

        &.error {
            background-color: #F94545;
        }
    }


    p.body3 {
        width: 100%;
        text-align: center;
    }

    p.body4 {
        margin-top: 20px;
        width: 100%;
        text-align: center;
    }

    p.body5 {
        margin-top: 20px;
        width: 100%;
        text-align: center;
    }

    .button-wrapper {
        display: flex;
        justify-content: center;
        margin-top: 60px;
    }

    @media (max-width: $mobile) {
        p.body3 {
            margin-top: 40px;
        }

        button {
            width: 100%;
        }
    }

    .wrapper {
        display: flex;
        justify-content: center;
        width: 100%;
    }

    form.contact {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 20px;
        max-width: 456px;
        width: 100%;



        .input-wrapper {
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-top: 8px;

            .label-wrapper {
                display: flex;
                justify-content: space-between;
                width: 100%;

                .required {
                    color: red;
                }
            }

            label {
                margin-bottom: 8px;
                align-self: flex-start;
            }

            input,
            textarea {
                color: $color-text;
                font-weight: bold;
                background: transparent;
                width: 100%;
                max-width: 456px;
                padding: 16px;
                border: 1px solid $color-secondary;
                border-radius: 10px;
                margin-bottom: 16px;
            }
        }

        button {
            width: 100%;
            max-width: 456px;
            margin-top: 10px;
        }
    }
}